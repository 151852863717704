<template>
  <div :qaid="`nav-list-${uid}`" class="nav-list-vue">
    <h4 v-if="columnHeader && !columnHeaderUrl" class="nav-list-vue__column-header" v-html="columnHeader" />
    <trek-link
      v-if="columnHeader && columnHeaderUrl"
      :href="composeLink(columnHeaderUrl)"
      class="nav-list-vue__column-header"
      v-html="columnHeader"
    />
    <ul
      :class="[
        'nav-list-vue__ul',
        {
          'nav-list-vue__ul--columns': colSpan,
          [`nav-list-vue__ul--col-count-${colSpan}`]: colSpan,
          [`nav-list-vue__ul--col-available-${colsAvailable}`]: colsAvailable,
        },
      ]"
    >
      <li
        v-for="(link, index) in links"
        :key="link.uid"
        :class="['nav-list-vue__li', {'nav-list-vue__li--columns': colSpan}]"
      >
        <div :class="['nav-list-vue__li-content', {'nav-list-vue__li-content--last-item': index === links.length - 1}]">
          <pdl-icon v-if="link.linkNavMenuIconName" class="nav-list-vue__icon" size="18" name="bolt" />

          <a
            :id="generateLinkId(link.uid)"
            :qaid="generateLinkId(link.uid)"
            role="link"
            :href="composeLink(link.url)"
            :target="setTarget(link.target)"
            class="nav-list-vue__link"
          >
            <span v-html="link.linkName" />
          </a>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import links from '../mixins/links';
import TrekLink from '@/components/TrekLink';
import {PdlIcon} from '@pedal/pdl-icon';

export default {
  name: 'NavList',
  components: {TrekLink, PdlIcon},
  mixins: [links],
  inject: ['idSuffix'],
  props: {
    columnHeader: {
      type: String,
      default: null,
    },
    columnHeaderUrl: {
      type: String,
      default: null,
    },
    links: {
      type: Array,
      required: true,
    },
    uid: {
      type: String,
      required: true,
    },
    colSpan: {
      type: Number,
      default: null,
    },
    colsAvailable: {
      type: Number,
      default: null,
    },
  },
  methods: {
    generateLinkId(linkId) {
      return this.idSuffix ? `'nav-link-'${linkId}${this.idSuffix}` : `'nav-link-'${linkId}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-list-vue {
  @apply px-2;

  --column-gap: 2rem;
  --nav-transition: all 0.25s ease-out;

  @include breakpoint(medium) {
    @apply px-3;
  }

  @include breakpoint(large) {
    @apply px-0;
  }

  &__column-header {
    @apply mb-2;
    @apply text-white;
    @apply leading-normal;
    @apply text-md;
    @apply font-bold;

    display: inline-block;
    transition: var(--nav-transition);

    &:focus {
      color: var(--nav-highlight-color);
    }

    @include breakpoint(large) {
      &:hover {
        color: var(--nav-highlight-color);
      }
    }

    &::before {
      content: '';
      border-left: 2px solid var(--nav-highlight-color);
      margin-right: 0.5rem;
    }

    @include breakpoint(large) {
      @apply text-sm;
    }
  }

  &__ul {
    &--columns {
      column-gap: var(--gap);
    }

    @include breakpoint(large) {
      &--col-count-2 {
        column-count: 2;

        &.nav-list-vue__ul--col-available-3 {
          width: calc((2 / 3 * 100%) - ((1 / 3) * var(--column-gap)));
        }

        &.nav-list-vue__ul--col-available-4 {
          width: calc((2 / 4 * 100%) - ((2 / 4) * var(--column-gap)));
        }
      }

      &--col-count-3 {
        column-count: 3;

        &.nav-list-vue__ul--col-available-4 {
          width: calc((3 / 4 * 100%) - ((1 / 4) * var(--column-gap)));
        }
      }

      &--col-count-4 {
        column-count: 4;
      }
    }
  }

  &__li {
    &--columns {
      break-inside: avoid;
    }
  }

  // Padding needs to be applied to an element inside the break-inside rules so that the columns dont split margins in safari/firefox
  &__li-content {
    padding-bottom: 0.75rem;
    display: flex;
    align-items: center;

    @include breakpoint(large) {
      align-items: flex-start;
    }

    &--last-item {
      @apply pb-0;
    }
  }

  &__icon {
    @apply -ml-25;

    display: inline-block;
    flex: none;
    fill: hsla(65, 50%, 50%, 1);
  }

  &__link {
    @apply text-gray-30;
    @apply font-medium;
    @apply text-md;

    display: inline-block;
    line-height: 1.2;
    transition: var(--nav-transition);

    &:focus {
      color: var(--nav-highlight-color);
    }

    @include breakpoint(large) {
      @apply text-sm;

      &:hover {
        color: var(--nav-highlight-color);
      }
    }
  }
}
</style>
