<template>
  <div v-if="isEnabled" class="flex flex-col justify-center items-center">
    <div :id="buttonContainerId" :class="classes"></div>
  </div>
</template>
<script>
import headTag from '@/utils/head-tag';
import {mapGetters, mapState} from 'vuex';
export default {
  name: 'LiveChat',
  props: {
    productCompareEnabledOnSite: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      buttonContainerId: '',
      isEnabled: false,
    };
  },
  computed: {
    ...mapState('backend', ['isB2Bsite', 'language', 'currentB2BUnit', 'liveChatOnPage']),
    ...mapState('user', ['userEmail', 'userName']),
    ...mapGetters('compare', ['currentRack']),
    classes() {
      return {
        'live-chat-button': true,
        'rack-displayed': this.currentRack?.length && this.productCompareEnabledOnSite,
      };
    },
  },
  mounted() {
    //8x8 Chat config is set up on page in CMS
    if (window.__8x8Chat && this.liveChatOnPage) {
      this.isEnabled = true;
      this.buttonContainerId = window.__8x8Chat.buttonContainerId;
      let busInfo = {
        currentUserName: this.userName,
        currentUserEmail: this.userEmail,
        languageISO: this.language,
      };
      if (this.isB2Bsite) {
        busInfo.currentB2BUnit = this.currentB2BUnit;
      }

      window.__8x8Chat.onInit = (bus) => bus.publish('customer:set-info', busInfo);

      //Append script once the rest of the config is set up how we want it.
      headTag.appendAsyncScript(
        'live-chat-script',
        `${window.__8x8Chat.domain}${window.__8x8Chat.path}/CHAT/common/js/chat.js`
      );
    }
  },
};
</script>
<style scoped lang="scss">
.live-chat-button {
  position: fixed;
  z-index: 16000002; //legacy value
  right: 0.5rem;
  bottom: 1rem;

  &.rack-displayed {
    bottom: 3.5rem;
  }
}
</style>
