<template>
  <div class="space-x-2 flex items-center">
    <span>
      {{ $t('checkout.financing.creditDebit') }}
    </span>
    <img :src="creditCardLogos" alt />
  </div>
</template>

<script>
import {mapState} from 'vuex';
export default {
  computed: {
    ...mapState('backend', ['cdnStaticFilesRoot']),
    creditCardLogos() {
      return `${this.cdnStaticFilesRoot}/_ui/desktop/theme-trek/img/cybersource-logo.svg`;
    },
  },
};
</script>
