<template>
  <section v-show="items.length" id="compareBar" qaid="compareBar" @click="openCompareRackDialog">
    <button class="compare-bar">
      <div class="compare-bar-counter-and-message" qaid="compareBarMessageContainer">
        <div class="compare-bar-counter" qaid="compareBarCounter" v-text="items.length"></div>
        <div class="compare-bar-message" qaid="compareBarMessage">{{ compareBarMessage }}</div>
      </div>
      <div class="compare-bar-add" qaid="compareBarAddButton">
        <pdl-icon name="add" :label="$t('basket.add.to.cart')" />
      </div>
    </button>
  </section>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import {PdlIcon} from '@pedal/pdl-icon';

export default {
  components: {
    PdlIcon,
  },
  data() {
    return {
      debug: false,
      testIndex: 0,
      scrollTopLocation: null,
      compareProductCardHeight: 0,
      isIphone: navigator.platform.indexOf('iPhone') != -1,
      isSafari: navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1,
    };
  },

  computed: {
    ...mapState('backend', ['encodedContextPath', 'unitId']),
    ...mapState('compare', ['racks']),

    compareBarMessage() {
      return this.items.length == 4 ? this.$t('productCompare.fullRack') : this.$t('productCompare.title');
    },

    isRackFull() {
      return this.items.length == 4;
    },

    compareHref() {
      return `${this.encodedContextPath}/compare/`;
    },

    items() {
      if (!Object.prototype.hasOwnProperty.call(this.racks, this.unitId)) return [];
      return this.racks[this.unitId];
    },
  },

  mounted() {
    this.createRack();
  },

  methods: {
    setProperRackHeightValue() {
      if (this.compareProductCardHeight <= 0) {
        // ignore if already set
        this.compareProductCardHeight = $('.compare-rack__product').first().height();
      }
    },

    openCompareRackDialog() {
      this.$store.commit('compare/setCompareRackDialog', true);
    },

    ...mapActions('compare', ['addToRack', 'createRack', 'removeFromRack']),
  },
};
</script>
