import { render, staticRenderFns } from "./LiveChat.vue?vue&type=template&id=7a9bd0f8&scoped=true&"
import script from "./LiveChat.vue?vue&type=script&lang=js&"
export * from "./LiveChat.vue?vue&type=script&lang=js&"
import style0 from "./LiveChat.vue?vue&type=style&index=0&id=7a9bd0f8&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a9bd0f8",
  null
  
)

export default component.exports