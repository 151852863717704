import {UnitTypes, RideTemperatureUnits} from '@/constants/unit-types';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

const unitType = [
  UnitTypes.IMPERIAL.milesString,
  UnitTypes.METRIC.kilometersAbbreviation,
  UnitTypes.IMPERIAL.feetAbbreviation,
  UnitTypes.METRIC.metersAbbreviation,
];
const temperatureUnit = [RideTemperatureUnits.FAHRENHEIT, RideTemperatureUnits.CELSIUS];
const weightUnit = [UnitTypes.IMPERIAL.weightString, UnitTypes.METRIC.weightString];
const heightUnit = [UnitTypes.IMPERIAL.feetAbbreviation, UnitTypes.METRIC.metersAbbreviation];

const MILE_PER_METER = 0.000621371;
const METERS_IN_ONE_MILE = 1609.34;
const METERS_IN_ONE_KM = 1000;
const FEET_PER_METER = 3.28084;
const METER_PER_FEET = 0.3048;
const KG_IN_ONE_LBS = 0.453592;
const LBS_IN_ONE_KG = 2.205;

export const convertMetersToDistanceUnit = (meters, preferredUnit) => {
  if (!unitType.includes(preferredUnit)) {
    throw new Error(`Meters cannot be converted to ${preferredUnit}.`);
  }
  if (preferredUnit === UnitTypes.IMPERIAL.milesString) {
    const miles = meters * MILE_PER_METER;
    return miles;
  } else {
    const kilometers = meters / METERS_IN_ONE_KM;
    return kilometers;
  }
};

export const convertPreferredUnitToMeters = (value, preferredUnit) => {
  if (!unitType.includes(preferredUnit)) {
    throw new Error(`${preferredUnit} cannot be converted to meters.`);
  }
  if (preferredUnit === UnitTypes.IMPERIAL.milesString) {
    return value * METERS_IN_ONE_MILE;
  } else if (preferredUnit === UnitTypes.METRIC.kilometersAbbreviation) {
    return value * METERS_IN_ONE_KM;
  } else if (preferredUnit === UnitTypes.IMPERIAL.feetAbbreviation) {
    return value * METER_PER_FEET;
  } else {
    return value;
  }
};

export const convertMetersToElevationUnit = (meters, preferredUnit) => {
  if (!unitType.includes(preferredUnit)) {
    throw new Error(`Meters cannot be converted to ${preferredUnit}.`);
  }
  if (preferredUnit === UnitTypes.IMPERIAL.milesString) {
    const feet = meters * FEET_PER_METER;
    return feet;
  } else {
    return meters;
  }
};

export const convertSecondsToHourMinutes = (seconds) => {
  const calculatedTime = dayjs.duration(seconds, 'seconds');
  return `${calculatedTime.hours().toString().padStart(2, '0')}:${calculatedTime
    .minutes()
    .toString()
    .padStart(2, '0')}`;
};

export const convertMetersPerSecondToPreferredPerHour = (metresPerSecond, preferredUnit) => {
  if (!unitType.includes(preferredUnit)) {
    throw new Error(`Meters cannot be converted to ${preferredUnit}.`);
  }
  if (preferredUnit === UnitTypes.IMPERIAL.milesString) {
    return 2.23694 * metresPerSecond;
  } else {
    return 3.6 * metresPerSecond;
  }
};

export const getTemperatureInPreferredUnit = (temperatureInCelsius, preferredTemperatureUnit) => {
  if (!temperatureUnit.includes(preferredTemperatureUnit)) {
    throw new Error(`celsius can not be converted to ${preferredTemperatureUnit}.`);
  }
  if (preferredTemperatureUnit === RideTemperatureUnits.FAHRENHEIT) {
    const fahrenheit = (temperatureInCelsius * 9) / 5 + 32;
    return fahrenheit;
  } else {
    return temperatureInCelsius;
  }
};

export const getWeightInPreferredUnit = (weight, preferredWeightUnit) => {
  if (!weightUnit.includes(preferredWeightUnit)) {
    throw new Error(`Weight can not be converted to ${preferredWeightUnit}.`);
  }
  if (preferredWeightUnit === UnitTypes.METRIC.weightString) {
    const weightInKgs = weight * KG_IN_ONE_LBS;
    return weightInKgs;
  } else {
    const weightInLbs = weight * LBS_IN_ONE_KG;
    return weightInLbs;
  }
};

export const convertUserHeightInPreferredUnit = (userHeight, preferredHeightUnit) => {
  if (!heightUnit.includes(preferredHeightUnit)) {
    throw new Error(`Height can not be converted to ${preferredHeightUnit}.`);
  }
  if (preferredHeightUnit === UnitTypes.METRIC.metersAbbreviation) {
    const heightInMts = userHeight * METER_PER_FEET;
    return heightInMts;
  } else {
    const heightInFts = userHeight * FEET_PER_METER;
    return heightInFts;
  }
};
