<template>
  <div class="space-x-2 flex items-center">
    <span>
      {{ $t('checkout.multi.paymentMethod.ideal') }}
    </span>
    <img :src="idealLogo" class="h-6" alt />
  </div>
</template>

<script>
import {mapState} from 'vuex';
export default {
  computed: {
    ...mapState('backend', ['cdnStaticFilesRoot']),
    idealLogo() {
      return `${this.cdnStaticFilesRoot}/_ui/desktop/theme-trek/img/ideal-logo.svg`;
    },
  },
};
</script>
