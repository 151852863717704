<template>
  <div class="trek-marquee-container">
    <template v-if="isSimplified">
      <div v-if="hasMarqueeBody" class="grid-x grid-margin-x">
        <div class="cell medium-8 medium-offset-2">
          <p class="text-lg text-center">{{ marquee.body }}</p>
        </div>
        <p v-if="hasMarqueeBodyCTA" class="celltext-center marquee-body-copy text-intro-weak">
          <!-- cms marquee link -->
          <slot name="cms-marquee-link"></slot>
        </p>
      </div>
    </template>
    <template v-else-if="marquee">
      <section
        :id="marquee.marqueeId"
        :ref="marquee.marqueeId"
        :class="[baseMarqueeClass, marqueeClasses]"
        class="bg-gray-50"
      >
        <template v-if="marquee.video">
          <video-component
            :video-src="marquee.video.url"
            :video-id="marquee.video.id"
            :description="marquee.title"
            :fallback-image-data="fallbackImageData"
          />
        </template>
        <template v-else-if="isCarousel">
          <div class="owl-carousel" data-owl-slider="fade">
            <image-component
              v-for="(image, index) in marquee.images"
              :key="index"
              :asset-id="image"
              :css-class="marqueeImageAlignment"
              img-format="pjpg"
              :is-data-object-fit="true"
              :alt-text="altText"
              transformation="fill"
              sizes="auto"
              :is-responsive="true"
            />
          </div>
        </template>
        <template v-else>
          <image-component
            :id="marquee.images[0]"
            :css-class="marqueeImageAlignment"
            :asset-id="marquee.images[0]"
            img-format="pjpg"
            :is-data-object-fit="true"
            :alt-text="altText"
            transformation="fill"
            sizes="auto"
            :is-responsive="true"
          />
        </template>

        <div :class="['fixed-overslider', textColor]" :style="hexColor">
          <div :class="['banner', 'mx-4', 'md:mx-6', marqueeTextAlignment]">
            <pdl-heading v-if="marquee.title" :level="marqueeHeaderLevel" size="xl" v-html="marquee.title" />
            <pdl-heading
              v-if="marquee.trekMarqueeSubHeader"
              size="md"
              class="mt-1"
              :level="marqueeSubHeaderLevel"
              v-html="marquee.trekMarqueeSubHeader"
            />

            <!-- CTA Buttons -->
            <slot name="buttons"></slot>
          </div>

          <div
            v-if="marquee.scrollToAnchor || marquee.photoCaption"
            class="absolute sm:hidden md:block py-2 bottom w-full"
          >
            <div v-if="marquee.scrollToAnchor" class="flex justify-center">
              <a
                class="marquee-arrow smooth-scroll"
                :href="marquee.scrollToAnchor"
                @click.prevent="scrollToAnchor(marquee.scrollToAnchor)"
              >
                <pdl-icon name="expand_more" :label="$t('text.skipToContent')" size="48" />
              </a>
            </div>
            <figure v-if="marquee.photoCaption" class="photo-caption">
              <figcaption class="caption">{{ marquee.photoCaption }}</figcaption>
              <pdl-icon name="photo_camera" size="32" />
            </figure>
          </div>
        </div>
      </section>
    </template>
  </div>
</template>
<script>
import {mapState} from 'vuex';
import {PdlIcon} from '@pedal/pdl-icon';
import {PdlHeading} from '@pedal/pdl-section-header';
import ImageComponent from '@/components/ImageComponent';
import VideoComponent from '@/components/VideoComponent';
import {scrollToElement} from '@/utils/scroll-to';

export default {
  name: 'TrekMarquee',

  components: {
    ImageComponent,
    VideoComponent,
    PdlIcon,
    PdlHeading,
  },

  props: {
    marquee: {
      type: Object,
      default: () => null,
    },
    isLockAspectRatio: {
      type: String,
      default: 'false',
    },
    isSimplified: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      baseMarqueeClass: 'trek-marquee',
    };
  },

  computed: {
    ...mapState('backend', ['languageISO']),

    fallbackImageData() {
      return {
        id: this.marquee.images[0],
        assetId: this.marquee.images[0],
        alignment: this.marqueeImageAlignment,
        altText: this.altText,
      };
    },
    isCarousel() {
      return this.marquee?.images?.length > 1;
    },
    lockAspectClass() {
      return this.isLockAspectRatio === 'true' ? 'trek-marquee--fixed-aspect' : '';
    },
    marqueeClasses() {
      const marqueeType = this.marquee.marqueeType ? this.marquee.marqueeType : 'medium';
      return [marqueeType, this.lockAspectClass];
    },
    marqueeTextAlignment() {
      return this.marquee.alignment ? this.marquee.alignment : '';
    },
    marqueeHeaderLevel() {
      const headerTag = this.marquee?.marqueeHeaderTag;
      const headerLevel = headerTag.split('h');
      return headerLevel[1];
    },
    marqueeSubHeaderLevel() {
      return parseInt(this.marqueeHeaderLevel) + 1;
    },

    marqueeImageAlignment() {
      let imageAlignment = 'center';
      if (this.marquee?.marqueeImageAlignment === 'left') {
        imageAlignment = 'left';
      } else if (this.marquee?.marqueeImageAlignment === 'right') {
        imageAlignment = 'right';
      }

      return `object-${imageAlignment} wallpaper fit-cover h-full`;
    },
    altText() {
      return this.marquee?.images[0]?.replace(/_/g, '-');
    },
    hexColor() {
      return `background-color: ${this.marquee?.hexColor}`;
    },
    textColor() {
      return this.marquee.textColor ? this.marquee.textColor : '';
    },
    hasMarqueeBody() {
      return Boolean(this.marquee?.body);
    },
    hasMarqueeBodyCTA() {
      return Boolean(this.marquee?.bodyCTA);
    },
  },

  methods: {
    scrollToAnchor(id) {
      scrollToElement(id, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.trek-marquee {
  .photo-caption {
    display: flex;
    align-items: center;

    @apply bottom-2;

    .caption {
      height: auto;
      line-height: normal;
    }
  }
}
</style>
