<template>
  <div :qaid="`nav-card-${card.uid}`" class="nav-card">
    <img v-if="card.img" :src="card.img" class="nav-card__img" :alt="altText" />
    <div v-if="card.text" class="nav-card__text" v-html="card.text" />
    <a
      v-if="card.action"
      class="nav-card__link"
      role="link"
      :href="composeLink(card.action.url)"
      :target="setTarget(card.action.target)"
    >
      <span class="nav-card__link-text" v-html="card.action.text" />
      <pdl-icon class="nav-card__link-icon" name="arrow_forward" />
    </a>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import links from '../mixins/links';
import {PdlIcon} from '@pedal/pdl-icon';

export default {
  name: 'NavCard',
  components: {
    PdlIcon,
  },
  mixins: [links],
  computed: {
    ...mapState('navigation', ['activeTab', 'currentOverlay']),
    card() {
      return this.currentOverlay?.tabs.find((tab) => tab.uid === this.activeTab)?.card;
    },
    altText() {
      return this.action?.text ?? '';
    },
  },
};
</script>

<style scoped lang="scss">
.nav-card {
  &__img {
    @apply mb-2;

    width: 100%;
  }

  &__text {
    @apply text-white;
    @apply text-sm;
    @apply leading-normal;
    @apply font-medium;
  }

  &__link {
    @apply mt-2;
    @apply text-gray-30;

    display: flex;
    align-items: center;
    transition: all 0.25s ease-out;

    &:hover,
    &:focus {
      color: var(--nav-highlight-color);
    }

    &-text {
      @apply mr-1;
      @apply text-sm;
      @apply leading-normal;
      @apply font-medium;
    }

    &-icon {
      color: var(--nav-highlight-color);
    }
  }
}
</style>
