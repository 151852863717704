import storefrontInstance from '@/api/instances/storefront';
import findIndex from 'lodash/findIndex';
import isEmpty from 'lodash/isEmpty';
import difference from 'lodash/difference';
import {PdlToastType} from '@/constants/pdl-toast-type';

const IMAGE_BASE_URL = 'https://media.trekbikes.com/image/upload';
const DEFAULT_IMAGE_URL = 'https://media.trekbikes.com/image/upload/f_auto,q_auto,w_80,h_60,c_fill/default-no-image';

const useResponseData = function ({state, commit, dispatch}, responseData, buildId, module, isReselectingTheme) {
  if (isReselectingTheme) {
    updateNewSelections({state, commit, dispatch}, responseData.selections, buildId);
  } else {
    commit('setSelections', responseData.selections);
    dispatch('fetchBikeEta', {buildId: buildId, selections: responseData.selections});
  }
  commit('setNavigation', responseData.navigation);

  dispatch('updateActiveNavigationStep');

  if (module === 'sizing') {
    // Separate the data for size drawer, otherwise right rail data is overwritten
    commit('setSizeData', responseData.groups);
  } else {
    commit('setModuleGroups', responseData.groups);
  }

  if (module === 'summary') {
    isModuleSummary(responseData.selections, responseData.modules);
    commit('setIsP1ConfigInCart', responseData.p1ConfigInCart);
    commit('setSummaryModules', responseData.modules);
    commit('setIsAddToCartVisible', responseData.addToCartVisible);
    delete responseData.p1ConfigInCart;
    delete responseData.modules;
  }

  if (module === 'paint-scheme') {
    commit('setColorCategories', responseData.colorCategories);
  }

  delete responseData.colorCategories;
  delete responseData.selections;
  delete responseData.navigation;
  delete responseData.groups;

  commit('setBikeData', responseData);
};

const updateNewSelections = function ({state, commit, dispatch}, selections, buildId) {
  const themeGroupIds = Object.values(state.themeGroups);
  let selectionsToRemove = [];
  let selectionsToAdd = [];
  let newSelections = [];
  state.selections.forEach((selection) => {
    if (themeGroupIds.indexOf(selection.groupId) > -1) {
      selectionsToRemove.push(selection);
    }
  });
  selections.forEach((selection) => {
    if (themeGroupIds.indexOf(selection.groupId) > -1) {
      selectionsToAdd.push(selection);
    }
  });
  newSelections = difference(state.selections, selectionsToRemove);
  newSelections = newSelections.concat(selectionsToAdd);
  commit('setSelections', newSelections);
  dispatch('fetchBikeEta', {buildId: buildId, selections: newSelections});
};

const isModuleSummary = function (selections, modules) {
  let personalizationText = null;
  selections?.forEach((selection) => {
    if (selection.groupId === 35 && selection.personalizationText) {
      personalizationText = selection.personalizationText;
    }
  });
  if (personalizationText) {
    modules?.forEach((module) => {
      if (module.id === 4) {
        module.groups.forEach((group) => {
          if (group.id === 35) {
            group.personalizationText = personalizationText;
          }
        });
      }
    });
  }
};

const projectOne = {
  namespaced: true,

  state: {
    availableHeight: null,
    isPageLoading: false,
    onSaveCallbackFunction: null,
    onSaveActionName: null,
    redirectUrl: '',
    bikePackage: {},
    baseImageUrl: IMAGE_BASE_URL,
    defaultImgUrl: DEFAULT_IMAGE_URL,
    selections: [],
    isThemeSelected: false,
    isReselectingTheme: false,
    navigation: [],
    moduleGroups: [],
    summaryModules: [],
    bikeData: {},
    bikeEta: '',
    bikeEtaVisible: false,
    railDataStack: [],
    currentNavigationStep: 0,
    currentRoute: 'chooseModel',
    cachedBuildId: '',
    cachedRedirectUrlB2c: '',
    cachedRedirectUrlB2b: '',
    cachedRedirectUrlAnonymous: '',
    cachedSelections: [],
    hasSelectionsChanged: false,
    toolbarDrawer: {
      isOpen: false,
      currentDrawerTab: null,
    },
    router: null,
    isResetSelectionsDialogVisible: false,
    isSaveModalVisible: false,
    isSizingModalVisible: false,
    isSummaryEmailModalVisible: false,
    hasQueuedSaveAction: false,
    queuedShareAction: undefined,
    hasQueuedDownloadAction: false,
    isAddToCartVisible: false,
    customerInformation: {
      name: '',
      email: '',
      phone: '',
      country: '',
      address: '',
      city: '',
      state: '',
      zip: '',
    },
    sizeData: [],
    isP1ConfigInCart: false,
    // unfortunately for now we need to use magic numbers here
    // these group IDs cover all the groups that may be affected when a user selects a new theme
    themeGroups: {
      SELECTED_THEME_ID: 9, // selected theme I.E. "Full Fade", "Solid Logo"
      HEADBADGE_COLOR_ID: 43, // selected headbadge color
      COLORWAY_ID: 68, // selected colorway for color scheme in "Splatter Fade" theme
      FINISH_ID: 94, // selected finish I.E. "matte", "gloss"
      COLOR_1_ID: 188, // primary selected color for theme
      COLOR_2_ID: 189, // second selected color for multi-color themes
      COLOR_3_ID: 190, // third selected color for multi-color themes
    },
    isHidingPrices: false,
    isHidingSummaryPrices: true,
    longestLeadTimeChoicesOnSummary: {},
    longestLeadTimeChoicesByGroup: {},
    isUpdatingSelection: false,
    invalidSelections: [],
    colorCategories: [],
  },

  getters: {
    activeRailData(state) {
      if (state.railDataStack && Array.isArray(state.railDataStack) && state.railDataStack.length) {
        return state.railDataStack[state.railDataStack.length - 1];
      }

      return {};
    },

    packageId({bikeData}) {
      return bikeData?.id ?? '';
    },

    hasValidCustomerInfo({customerInformation}) {
      if (isEmpty(customerInformation)) return false;
      if (isEmpty(customerInformation.name)) return false;
      if (isEmpty(customerInformation.email)) return false;
      if (isEmpty(customerInformation.country)) return false;
      if (isEmpty(customerInformation.address)) return false;
      if (isEmpty(customerInformation.city)) return false;
      if (isEmpty(customerInformation.zip)) return false;

      if (customerInformation.country == 'US' && isEmpty(customerInformation.state)) return false;

      return true;
    },

    longestChoiceLeadTimesByGroup: (state) => (groupId) => {
      return state.longestLeadTimeChoicesByGroup[groupId];
    },
  },

  mutations: {
    setBikePackage(state, payload) {
      state.bikePackage = payload;
    },

    setCachedRedirectData(state, payload) {
      state.cachedRedirectUrlB2c = payload.redirectUrlB2c;
      state.cachedRedirectUrlB2b = payload.redirectUrlB2b;
      state.cachedRedirectUrlAnonymous = payload.redirectUrlAnonymous;
      state.cachedBuildId = payload.buildId;
      state.cachedSelections = payload.selections;
    },

    setRedirectUrl(state, payload) {
      state.redirectUrl = payload;
    },

    setSelections(state, payload) {
      // Ensure selections is always an array, even if empty.
      state.selections = payload || [];
    },

    setIsThemeSelected(state, payload) {
      state.isThemeSelected = payload;
    },

    setIsReselectingTheme(state, payload) {
      state.isReselectingTheme = payload;
    },

    setNavigation(state, payload) {
      if (Array.isArray(payload) && payload.length) {
        // making sizing not active for now
        let index = payload.findIndex((obj) => obj.name === 'sizing');

        let arrayWithSizingDisabled = [...payload];
        arrayWithSizingDisabled[index].isActive = false;

        // chooseModel and summary are always active/static but not returned from the BE. Adding them here
        let navigationLinks = [
          {name: 'chooseModel', isActive: true},
          ...arrayWithSizingDisabled,
          {name: 'summary', isActive: true},
        ];
        state.navigation = navigationLinks;
      }
    },

    setCurrentRoute(state, payload) {
      state.currentRoute = payload;
    },

    setHasQueuedSaveAction(state, payload) {
      state.hasQueuedSaveAction = payload;
    },

    setQueuedShareAction(state, payload) {
      state.queuedShareAction = payload;
    },

    setModuleGroups(state, payload) {
      state.moduleGroups = payload;
    },

    setSummaryModules(state, payload) {
      state.summaryModules = payload;
    },

    setBikeData(state, payload) {
      state.bikeData = {...payload};
    },

    setBikeDataConfigurationCode(state, payload) {
      state.bikeData.code = payload;
    },

    setBikeDataConfigurationDescription(state, payload) {
      state.bikeData.description = payload;
    },

    setPageLoading(state, payload) {
      state.isPageLoading = payload;
    },

    setBikeDataEta(state, payload) {
      state.bikeEta = payload;
    },

    setBikeEtaVisible(state, payload) {
      state.bikeEtaVisible = payload;
    },

    pushToRailDataStack(state, payload) {
      state.railDataStack.push(payload);
    },

    popRailDataStack(state) {
      state.railDataStack.pop();
    },

    clearRailDataStack(state) {
      state.railDataStack = [];
    },

    clearCachedRedirectData(state) {
      state.cachedBuildId = '';
      state.cachedRedirectUrlB2b = '';
      state.cachedRedirectUrlB2c = '';
      state.cachedRedirectUrlAnonymous = '';
    },

    clearCachedSelections(state) {
      state.cachedSelections = [];
    },

    setToolbarDrawerOpenState(state, payload) {
      state.toolbarDrawer.isOpen = payload;
    },

    setToolbarDrawerCurrentTab(state, payload) {
      state.toolbarDrawer.currentDrawerTab = payload;
    },

    setRouter(state, payload) {
      state.router = payload;
    },

    setIsResetSelectionsDialogVisible(state, payload) {
      state.isResetSelectionsDialogVisible = payload;
    },

    setIsSaveModalVisible(state, payload) {
      state.isSaveModalVisible = payload;
    },

    setIsSizingModalVisible(state, payload) {
      state.isSizingModalVisible = payload;
    },

    setIsSummaryEmailModalVisible(state, payload) {
      state.isSummaryEmailModalVisible = payload;
    },

    setOnSaveCallbackFunction(state, payload) {
      state.onSaveCallbackFunction = payload;
    },

    setOnSaveActionName(state, payload) {
      state.onSaveActionName = payload;
    },

    setIsAddToCartVisible(state, payload) {
      state.isAddToCartVisible = payload;
    },

    calculateApplicationHeight(state) {
      const windowHeight = window.innerHeight;
      const trekHeader = document.getElementById('trek-header');
      const projectOneHeader = document.getElementById('project-one-main-nav');
      const headerHeight = trekHeader ? trekHeader.offsetHeight : 0;
      const projectOneHeaderHeight = projectOneHeader ? projectOneHeader.offsetHeight : 0;

      if (windowHeight && headerHeight && projectOneHeaderHeight) {
        const availableHeight = windowHeight - headerHeight - projectOneHeaderHeight;
        state.availableHeight = availableHeight;
      }
    },

    setCurrentNavigationStep(state, payload) {
      state.currentNavigationStep = payload;
    },

    setCustomerName(state, payload) {
      state.customerInformation.name = payload;
    },

    setCustomerEmail(state, payload) {
      state.customerInformation.email = payload;
    },

    setCustomerPhone(state, payload) {
      state.customerInformation.phone = payload;
    },

    setCustomerCountry(state, payload) {
      state.customerInformation.country = payload;
    },

    setCustomerAddress(state, payload) {
      state.customerInformation.address = payload;
    },

    setCustomerCity(state, payload) {
      state.customerInformation.city = payload;
    },

    setCustomerState(state, payload) {
      state.customerInformation.state = payload;
    },

    setCustomerZip(state, payload) {
      state.customerInformation.zip = payload;
    },

    setHasSelectionsChanged(state, payload) {
      state.hasSelectionsChanged = payload;
    },

    setSizeData(state, payload) {
      state.sizeData = payload;
    },

    setIsP1ConfigInCart(state, payload) {
      state.isP1ConfigInCart = payload;
    },

    setIsHidingPrices(state, payload) {
      state.isHidingPrices = payload;
    },
    setLongestLeadTimeChoicesByGroup(state, payload) {
      state.longestLeadTimeChoicesByGroup = payload;
    },
    setLongestLeadTimeChoicesOnSummary(state, payload) {
      state.longestLeadTimeChoicesOnSummary = payload;
    },
    setIsUpdatingSelection(state, payload) {
      state.isUpdatingSelection = payload;
    },
    setInvalidSelections(state, payload) {
      state.invalidSelections = payload;
    },
    setColorCategories(state, colorCategories) {
      state.colorCategories = colorCategories;
    },
  },

  actions: {
    // fetches a list of all packages available to the user
    async fetchPackages() {
      try {
        const response = await storefrontInstance.get(`/project-one/api/packages/`);

        if (response?.data?.data) {
          return response.data.data;
        }
      } catch (error) {
        return error;
      }
    },
    // Fetches and updates data for an un-saved bike package. Receives a bike ID as payload.
    async fetchBikePackage({commit, state, dispatch}, payload) {
      if (payload) {
        try {
          const response = await storefrontInstance.get(`/project-one/api/packages/${payload}/`);

          if (response.status === 200) {
            commit('setBikePackage', response.data.data);
            commit('setBikeData', response.data.data.packages[0] || {});
            commit('setSelections', state.cachedSelections);
            commit('setNavigation', response.data.data.navigation);

            dispatch('updateActiveNavigationStep');
            return response.status;
          } else {
            /* handle invalid packageId case */
            commit('setBikePackage', null);
            window.location.href = state.redirectUrl;
            return response.status;
          }
        } catch (error) {
          commit('setBikePackage', null);
          window.location.href = state.redirectUrl;
          return error;
        }
      } else {
        // if packageId is missing redirect
        window.location.href = state.redirectUrl;
      }
    },
    // Fetches and updates data for a saved configuration.
    async fetchBikeConfiguration({commit, state, dispatch}, payload) {
      if (payload) {
        try {
          const configurationCode = payload?.includes('P-') ? payload : `P-${payload}`;
          const response = await storefrontInstance.get(`/project-one/api/configurations/${configurationCode}/`);

          if (response.status === 200) {
            commit('setNavigation', response.data.data.navigation);
            dispatch('updateActiveNavigationStep');

            commit('setBikePackage', {
              packages: response.data.data.packages,
              platform: response.data.data.platform,
              navigation: response.data.data.navigation,
            });

            delete response.data.data.navigation;
            delete response.data.data.platform;
            delete response.data.data.packages;

            commit('setBikeData', response.data.data);

            return response.status;
          } else {
            // TODO: Is there a better null case that a page refresh?
            commit('setBikeData', null);
            window.location.href = state.redirectUrl;
            return response.status;
          }
        } catch (error) {
          // TODO: Is there a better null case that a page refresh?
          commit('setBikeData', null);
          window.location.href = state.redirectUrl;
          return error;
        }
      } else {
        window.location.href = state.redirectUrl;
      }
    },
    // Fetches and updates data used by specific P1 modules/stages during customization.
    async fetchModule(
      {state, commit, dispatch},
      {buildId, module, themeId, presetPK, forceGet, isReselectingTheme, isToast}
    ) {
      const buildEndpointType = buildId?.startsWith('P-') ? 'configurations' : 'packages';
      let selectionsUrl = `/project-one/api/${buildEndpointType}/${buildId}/selections/${module}/`;

      if (themeId) selectionsUrl += `${themeId}/`;

      if (presetPK) selectionsUrl += `?presetPK=${presetPK}`;

      if (selectionsUrl) {
        try {
          let response = null;

          // forceGet is a custom param being passed in from ProjectOnePaintandThemeCustomizer. If false, do the POST to reflect user selections
          if (!forceGet && !isReselectingTheme && Array.isArray(state.selections) && state.selections.length) {
            response = await storefrontInstance.post(selectionsUrl, {selections: state.selections}, {toast: isToast});
          } else {
            // Otherwise, GET theme presets/start fresh
            response = await storefrontInstance.get(selectionsUrl, {toast: isToast});
          }

          if (response.data?.data) {
            useResponseData({state, commit, dispatch}, response.data.data, buildId, module, isReselectingTheme);
          }
        } catch (error) {
          console.error('Error when retrieving selections from API', error);
        }
      } else {
        console.error('Attempted to retrieve a selections without a valid ID');
      }
    },

    /**
     * Setting the Toolbar Drawer tab and toggling drawer
     */
    setDrawerCurrentTabAndOpenState({commit}, payload) {
      //close the drawer if payload is null, takes a boolean
      commit('setToolbarDrawerOpenState', !!payload);

      commit('setToolbarDrawerCurrentTab', payload);
    },

    proceedToNextStep({state}) {
      let activeSteps = state.navigation.filter((step) => {
        return step.isActive;
      });
      let currentIndex = findIndex(activeSteps, function (step) {
        return step.name == state?.router?.currentRoute?.name;
      });
      if (currentIndex < activeSteps.length - 1 && state.router) {
        state.router.push({name: activeSteps[currentIndex + 1].name});
      } else {
        state.router.push({name: 'summary'});
      }
    },

    /**
     * Setting the Bike ETA
     */
    async fetchBikeEta({commit}, payload) {
      const buildEndpointType = payload?.buildId?.startsWith('P-') ? 'configurations' : 'packages';

      const leadTimeUrl = `/project-one/api/${buildEndpointType}/${payload.buildId}/lead-time/`;

      try {
        // If we have payload.selection then we have to make POST request with body as updated selections otherwise simple GET request.
        const response =
          payload.selections && buildEndpointType === 'packages'
            ? await storefrontInstance.post(leadTimeUrl, {selections: payload.selections})
            : await storefrontInstance.get(leadTimeUrl);

        if (response.data.data.longestChoiceLeadTimeOnSummary?.choiceLeadTimes) {
          let longestLeadTimeChoicesOnSummary = {};
          response.data.data.longestChoiceLeadTimeOnSummary.choiceLeadTimes.forEach((choiceData) => {
            longestLeadTimeChoicesOnSummary[choiceData.choiceId] = choiceData.leadTimeDays;
          });
          commit('setLongestLeadTimeChoicesOnSummary', longestLeadTimeChoicesOnSummary);
        }
        if (response.data.data.longestGroupChoiceLeadTimes) {
          let longestLeadTimeChoicesByGroup = {};
          response.data.data.longestGroupChoiceLeadTimes.forEach((choiceData) => {
            let choiceLeadTimes = {};
            choiceData.choiceLeadTimes.forEach((choice) => {
              choiceLeadTimes[choice.choiceId] = choice.leadTimeDays;
            });
            longestLeadTimeChoicesByGroup[choiceData.groupId] = choiceLeadTimes;
          });
          commit('setLongestLeadTimeChoicesByGroup', longestLeadTimeChoicesByGroup);
        }
        if (response.status === 200) {
          commit('setBikeEtaVisible', true);
          commit('setBikeDataEta', response.data.data.packageLeadTime);
        } else if (response.status === 204) {
          commit('setBikeEtaVisible', false);
        }
      } catch (error) {
        console.error(error);
        return error;
      }
    },

    async updateSelection({commit, state, dispatch}, {buildId, module, selections, isSelectionsActive = true}) {
      try {
        const buildEndpointType = buildId?.startsWith('P-') ? 'configurations' : 'packages';
        const selectionsUrl = `/project-one/api/${buildEndpointType}/${buildId}/selections/${module}/`;

        const selectionsToUpdateMap = selections?.reduce((map, selection) => {
          map[selection.groupId] = selection;
          return map;
        }, {});

        const updatedSelections = structuredClone(state.selections).map((selection) => {
          const updatedSelection = selectionsToUpdateMap[selection.groupId];
          if (updatedSelection && updatedSelection.choiceId !== selection.choiceId) {
            updatedSelection.oldChoiceId = selection.choiceId;
            return {...updatedSelection};
          }
          return selection;
        });

        commit('setIsUpdatingSelection', true);

        const response = await storefrontInstance.post(selectionsUrl, {selections: updatedSelections});

        if (response.data?.data) {
          const responseData = response.data.data;

          if (isSelectionsActive) {
            commit('setSelections', responseData.selections);
          }

          commit('setIsThemeSelected', true);
          dispatch('fetchBikeEta', {buildId, selections: responseData.selections});
          commit('setNavigation', responseData.navigation);
          dispatch('updateActiveNavigationStep');

          if (module === 'sizing') {
            commit('setSizeData', responseData.groups);
          } else {
            commit('setModuleGroups', responseData.groups);
          }

          ['selections', 'navigation', 'groups'].forEach((key) => delete responseData[key]);

          commit('setBikeData', responseData);
          commit('setHasSelectionsChanged', true);

          if (responseData.invalidSelections?.length > 0) {
            const invalidSelections = responseData.invalidSelections;
            const action = invalidSelections.map((selection) => selection.groupTitle).join(', ');

            window.vm.toast({
              type: PdlToastType.ERROR,
              duration: 0,
              showClose: true,
              title: window.vm.$t('projectOne.config.incompatible.options'),
              message: window.vm.$t('projectOne.config.incompatible.conflicts'),
              action: action,
            });

            commit('setInvalidSelections', invalidSelections);
          } else {
            commit('setInvalidSelections', []);
          }
        }
      } catch (error) {
        console.error('Error updating selections', error);
      } finally {
        commit('setIsUpdatingSelection', false);
      }
    },

    async saveConfig({state, commit}, {forceNew, isToast}) {
      let data = {
        code: forceNew ? null : state.bikeData?.code,
        packageId: state.bikeData?.id,
        description: state.bikeData?.description,
        selections: state.selections,
        p1ImageUrl: state.bikeData?.imageUrl,
        packageDescription: state.bikeData?.modelName,
      };

      const method = state.bikeData?.code && !forceNew ? 'put' : 'post';
      await storefrontInstance[method]('/project-one/api/configurations/', data, {
        toast: isToast,
      })
        .then((response) => {
          if (response.status === 200 && response.data?.data) {
            commit('setBikeDataConfigurationCode', response.data.data.code);
            commit('setBikeDataConfigurationDescription', response.data.data.description);
            state.router.push({name: state.currentRoute, params: {buildId: response.data.data.code}});

            // Selection that have been changed, now saved in backedoffice, hence setting it to false
            commit('setHasSelectionsChanged', false);
            return response;
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            // We receive a 401 status when the user saving the bike is different from
            // the original creator of the bike. In that case we simply need to create
            // a new (copy) of the bike. So the save dialog is displayed.
            commit('setIsSaveModalVisible', true);
          } else {
            console.error(error);
            throw new Error(error);
          }
        })
        .finally(() => {
          commit('setHasQueuedSaveAction', false);
        });
    },

    async submitToRetailer({state, commit}, payload) {
      let data = {
        code: state.bikeData?.code || null,
        packageId: state.bikeData?.id,
        description: state.bikeData?.description,
        selections: state.selections,
        p1ImageUrl: state.bikeData?.imageUrl,
        dealerId: payload.dealerId,
        customerAddress: {
          contactMethod: payload.contactMethod,
          email: payload.email,
          phone: payload.phone,
          name: payload.name,
        },
      };

      await storefrontInstance
        .post('/project-one/api/configurations/submit', data, {
          toast: false,
        })
        .then((response) => {
          if (response.status === 200 && response.data?.data) {
            commit('setOnSaveCallbackFunction', null, {root: true});
            state.router.push({name: 'summary'});
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            // We receive a 401 status when the user saving the bike is different from
            // the original creator of the bike. In that case we simply need to create
            // a new (copy) of the bike. So the save dialog is displayed.
            commit('setIsSaveModalVisible', true);
          } else {
            console.error(error);
          }
        });
    },

    updateActiveNavigationStep({commit, state}, routeName) {
      if (routeName) commit('setCurrentRoute', routeName);

      const activeLinks = state?.navigation?.filter((link) => link.isActive);

      if (Array.isArray(activeLinks)) {
        const linkIndex = activeLinks.findIndex((link) => link.name === state.currentRoute);
        if (linkIndex > -1) commit('setCurrentNavigationStep', linkIndex);
      }
    },

    async fetchCustomerInfo({commit, state}) {
      try {
        const response = await storefrontInstance.get(`/project-one/api/configurations/${state.bikeData.code}`);
        const data = response.data.data?.customerInformation;

        commit('setBikeDataConfigurationDescription', data?.description);
        commit('setCustomerName', data?.name);
        commit('setCustomerEmail', data?.email);
        commit('setCustomerPhone', data?.phone);
        commit('setCustomerCountry', data?.country);
        commit('setCustomerAddress', data?.address);
        commit('setCustomerCity', data?.city);
        commit('setCustomerState', data?.state);
        commit('setCustomerZip', data?.zipCode);
      } catch (error) {
        console.error('fetchCustomerInfo error:', error);
        return error;
      }
    },

    async saveCustomerInfo({state, commit}, payload) {
      let updatedCustomerInfo = {
        description: state.bikeData.description,
        name: payload?.name ?? state.customerInformation.name,
        email: payload?.email ?? state.customerInformation.email,
        phone: payload?.phone ?? state.customerInformation.phone,
        country: payload?.country ?? state.customerInformation.country,
        address: payload?.address ?? state.customerInformation.address,
        city: payload?.city ?? state.customerInformation.city,
        state: payload?.state ?? state.customerInformation.state,
        zipCode: payload?.zip ?? state.customerInformation.zip,
      };

      await storefrontInstance
        .put(`/project-one/api/configurations/${state.bikeData.code}/updateCustomerInformation`, updatedCustomerInfo)
        .then((response) => {
          if (response.status === 200 && response.data?.data) {
            commit('setOnSaveCallbackFunction', null);
          }
        })
        .catch((error) => {
          console.error('Error while updating customer information:', error);
        });
    },

    addToCartValidation({state, commit, getters}) {
      // Is it saved?
      if (!(state.bikeData?.code && state.bikeData?.description)) {
        commit('setOnSaveActionName', 'addToCartValidation');
        commit('setIsSaveModalVisible', true);
        return false;
      }

      // Is the customer info filled out?
      if (!getters.hasValidCustomerInfo && state.bikeData.packageType !== 'WarrantyFrameset') {
        commit('setToolbarDrawerOpenState', true);
        commit('setToolbarDrawerCurrentTab', 'ProjectOneToolbarCustomerInfo');
        return false;
      }

      commit('setIsSizingModalVisible', true);
    },

    async addToCart({state, commit, dispatch}) {
      // Save config again right before adding to cart (without toast)
      await dispatch('saveConfig', {isToast: false});

      const addToCartResponse = await storefrontInstance.put('/project-one/cart/entries', {
        projectOneConfigurationCode: state.bikeData?.code,
      });

      if (addToCartResponse.status === 200 && addToCartResponse.data?.data) {
        commit('setOnSaveCallbackFunction', null);
        commit('setOnSaveActionName', null);

        dispatch('miniCart/fetchMiniCart', null, {root: true});
        dispatch('fetchModule', {
          buildId: state.bikeData?.code,
          module: 'summary',
        });
      } else {
        console.error('There was a problem adding to cart');
      }
    },
  },
};

export default projectOne;
